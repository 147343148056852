.contact{
	@include main-page-padding;
	@include green-links;
}

.contact__inner{
	@include article-page-inner;

	h2{
		margin-bottom: 0;
		font-size: $h3-font-size-sm;

		@include breakpoint($screen-sm){
			font-size: $h2-font-size;
		}
	}

	p{
		margin: 0 0 $global-padding-md;
	}
}