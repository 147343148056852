.video-gallery__loading{
	opacity: 0;
	@include halign-abs;
}

/*
Feature
*/

.video-gallery__feature, .video-gallery__feature--spacer{ 
	margin-bottom: $global-padding-md;
	position: relative;
	@include aspect-ratio(16, 9);

	videogular vg-overlay-play, [videogular] vg-overlay-play {
		left: 0;
	}
}

.video-gallery__feature--spacer{
	width: 100%;

	@include breakpoint($screen-sm){
		display: none;
	}
}

.video-gallery__feature--scrolled{
	@include breakpoint($screen-max-sm){
		position: fixed;
	    z-index: 90;
	    top: 0;
	    left: 0;
	    width: 100%;
	    @include main-page-inner;

	    &:before{
	    	content: '';
	    	position: absolute;
	    	height: 100%;
	    }
	}
}

.video-gallery__feature-prev, .video-gallery__feature-next{
	@include valign-abs;
	width: $how-to-poi-video-nav-size;
	cursor: pointer;
	transform-origin: center;
	display: none;

	@include breakpoint($screen-md	){
		display: block;
	}

	svg{
		stroke: $white;
	}

	&:hover{
		svg{
			stroke: $primary-color;
		}
	}
	
}
.video-gallery__feature-prev{
	@include calc(left, "-#{$how-to-poi-video-nav-size} - #{$global-padding-lg}");
	transform: rotate(0deg);
}

.video-gallery__feature-next{
	@include calc(right, "-#{$how-to-poi-video-nav-size} - #{$global-padding-lg}");
	transform: rotate(180deg);
}


/*
Thumbnails
*/

.video-gallery__previews{
	@include container;
	max-width: $screen-xs;

	@include breakpoint($screen-sm){
		max-width: 100%;
		display: flex;
		flex-flow: row wrap;
		justify-content: flex-start;
	  	align-items: flex-start;
	}
}

.video-gallery__preview-wrapper{
	width: 100%;

	@include tile-wrapper;
	@include default-transition();

	@include breakpoint($screen-max-sm){
		@include container;
	}

	@include breakpoint($screen-sm){
		@include span(6);
		padding: 0 $global-padding-xs / 2 !important;
	}

	@include breakpoint($screen-md){
		@include span(4);
	}

	&:hover{
		.video-gallery__play{
	    	&:before{
	    		@include diamond($primary-color, none, $vg-play-diamond-size-sm);

	    		@include breakpoint($screen-sm){
	    			@include diamond($primary-color, none, $vg-play-diamond-size);
	    		}
	    	}
		}
	}
}

.video-gallery__thumb{
	width: 100%;
	@include aspect-ratio(16, 9);
	background-size: cover;

	@include breakpoint($screen-max-sm){
		/*@include span(6);*/
		width: 70%;
		margin: auto;
	}
}

.video-gallery__play{
	@include play-button-overlay($vg-play-diamond-size-sm, $vg-play-icon-size-sm);

	@include breakpoint($screen-sm){
		@include play-button-overlay($vg-play-diamond-size, $vg-play-icon-size);
	}
}

.video-gallery__thumb--selected{
	&:after{
		content: '';
		position: absolute;
		width: 100%;
		height: 100%;
		box-sizing: border-box;
		border: 2px $primary-color solid;
		top: 0;
		left: 0;

		@include breakpoint($screen-sm){
			border-bottom: none;
		}
	}	

	.video-gallery__play{
		display: none;
	}
}


.video-gallery__heading{
	@include breakpoint($screen-max-sm){
		h2{
			font-size: $h3-font-size-sm;
		}
	}

	@include breakpoint($screen-sm){
		@include info-heading($primary-color);
	}
}

.video-gallery__p{
	padding: $global-padding-sm $global-padding-md;

	p{font-size: $info-box-paragraph-font-size;}

	@include breakpoint($screen-max-sm){
		text-align: center;
		padding-top: 0;

		p{
			margin-top: 0;
		}
	}
}