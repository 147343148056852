.homepage{
	width: 100%;
	height: 100%;
	position: relative;

	@include breakpoint($screen-sm){
		padding-top: 0;
		position: absolute;
		width: 100%;
	}
}

.homepage__footer .footer{
	position: absolute;

	@include breakpoint($screen-v-max-sm){
		position: relative;
	}

	@include breakpoint($screen-sm){
    	position: absolute;
    }
}

.homepage__bg-video-wrapper{
	
}

.homepage__bg-video-overlay{
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0;
	left: 0;
	background-color: rgba($black, 0.3);	
}

.homepage__bg-video{
	@include video-bg();
}

.homepage__bg-img{
	@include cover-background("../images/backgrounds/background-home.jpg");
	opacity: 1;
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	z-index: -1;
}

.homepage__inner{
	@include container($homepage-content-width);
	@include calc(padding-top, #{$header-logo-size-sm} + #{$global-padding-lg});
	opacity: 1;

	@include breakpoint($screen-sm-w-h){
		width: $homepage-content-width;
		padding-top: 0;
		@include screen-center;
	}

	@include breakpoint($screen-sm){
		width: $homepage-content-width;
	}
}


.homepage__h4{
	margin-bottom: $global-padding-xs;
}

.homepage__anim-text{
	
}

.homepage__lead-text{
	max-width: 400px;
	padding: 0 $global-padding-md;
	margin: auto;
}


/*CAROUSEL*/
.homepage__quick-links{
	margin-bottom: $global-padding-lg;
	width: 100%;
	max-width: $homepage-content-width;
	overflow: hidden;

	@include breakpoint($screen-sm){
		margin-bottom: 0;
	}

	.owl-item{
		opacity: 0.3;
		@include default-transition();

		@include breakpoint($screen-sm){
			opacity: 1;
		}

		&.center{
			opacity: 1;
		}
	}

	.owl-controls{
		margin-top: $global-padding-xs;
		@include breakpoint($screen-sm){
			display: none;
		}
	}
}

.homepage__quick-links-item{
	position: relative;
	padding: $global-padding-md 0;
}

.homepage__quick-links-item-bg{
	@include bg-pattern;
	position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.3;

    &:after{
    	opacity: 1;
    }

	@include breakpoint($screen-sm){
		opacity: 0;
	}
}

.homepage__quick-links-icon-wrapper{
	height: $quick-links-icon-wrapper-height;
}

.homepage__quick-links-icon{
	@include valign();
	pointer-events:none;
	overflow: visible !important;
}

.homepage__quick-links-icon--doco{
	width: $quick-links-icon-doco-width;
}

	.homepage__quick-links-icon-doco-path{
		transform-origin: center;
	}

	.homepage__quick-links-icon-doco-path--hover{
		opacity: 0;
	}

.homepage__quick-links-icon--360{
	width: $quick-links-icon-360-width;

}

	.homepage__quick-links-icon-360-arrows{
		transform-origin: center;
		transform-origin: 35px center;
	}

.homepage__quick-links-icon--map{
	height: $quick-links-icon-map-height;
}

	.homepage__quick-links-icon-map-marker{
		fill: $black;
		stroke: $white;
		stroke-miterlimit:10;
		opacity: 0;
	}

.homepage__quick-links-anchor{
	&:hover{
		color: $body-font-color;

		.homepage__quick-links-icon{
			fill: $body-font-color;			
		}
	}
}

.homepage__quick-links-label{
	pointer-events:none;
	font-weight: bold;
	margin-bottom: 0;
}

.homepage__quick-links-subtitle{
	margin: 0;

	@include breakpoint($screen-sm){
		opacity: 0;
		/*transform: translate(0, -1em);
		@include pattern-text-transition();*/
	}	
}