.info-box__modal{
	position: absolute;
	background: rgba($black, 1); 
	z-index: 310;   
    width: 100%;
    min-height: 100%;
    @include container();
    /*@include ng-animate-fade($default-transition-duration);*/

    @include breakpoint($screen-sm){
    	@include calc(top, #{$header-logo-size} + #{$global-padding-lg});
    	width: 50%;
    	left: $global-padding-md;
    	z-index: 102;
    	min-height: inherit;
    }

    @include breakpoint($screen-md){
    	width: 45%;
    }

    @include breakpoint($screen-lg){
    	width: 33%;
    	left: 5%;
    }
}

.info-box__modal-media{
	img, video{
		display: block;
	}
}

.info-box__modal-heading{
	@include info-heading($red);	
}

.info-box__modal-heading--blue{
	@include info-heading($primary-color);
}

.info-box__modal-heading--red{
	@include info-heading($red);
}

.info-box__modal-heading--green{
	@include info-heading($green);
}

.info-box__modal-heading--purple{
	@include info-heading($purple); 
}

.info-box__modal-heading--orange{
	@include info-heading($orange);
}

.info-box__modal-heading--yellow{
	@include info-heading($yellow);
}

.info-box__modal-heading-icon{
	@include halign-abs;
	background-color: $red;
	width: 50px;
	height: 36px;
    bottom: -22px;
	background-size: 50px;
	background-image: url(../images/title-symbol@2x.png);
	z-index: 10;
	background-position: bottom center;
}

.info-box__modal-heading-svg{
	width: $info-box-icon-size;
	height: $info-box-icon-size;
	z-index: 5;

	path{
		fill: $black;	
	}
	
}

.info-box__modal-content{
	padding: $global-padding-sm $global-padding-md;

	@include breakpoint($screen-sm){
		padding: $global-padding-sm $global-padding-md;
	}
	
	p{font-size: $info-box-paragraph-font-size;}
}

.info-box__modal-close{
    @include close-icon-diamond();
    &:before {
    	z-index: 3;
    }
}

.info-box__show-button{
	@include button($white);
	@include halign-abs;
	bottom: 30%;
	z-index: 10;

	@include ng-animate-fade($default-transition-duration);
}