.soc{
	position: absolute;
	width: 100%;
	height: 100%;
}

.soc__videogular-container {
	width: 100%;
	height: 100%;
	margin: auto;
	/*overflow: hidden;*/

  vg-media video{
    display: block;
  }

  vg-overlay-play .overlayPlayContainer div{
    display: none !important;
  }
}

.soc__videogular-theme{
	.iconButton {
		border: none; 
	    position: relative;
	    z-index: 1;
	}

	vg-controls.videogular__header-controls {
		z-index: 200;
    @include topbar-controls-wrapper;
    display: none;

    @include breakpoint($screen-sm){
      display: block;
    }

    .controls-container{
      background: none;
      height: $header-height;
      @include calc('padding-top', ($header-logo-size-sm / 2) - ($header-height));

      @include breakpoint($screen-sm){
			@include calc('padding-top', ($header-logo-size / 2) - ($header-height));
		}

      .iconButton{
        @include topbar-icon();
        color: inherit;
        transition: none;
        left: $global-padding-xs;
        padding:0;
      }
	    }
	 }

  .videogular__header-controls-item{
    @include topbar-list-item();
  }

  .videogular__header-controls-label{
    @include topbar-icon-label();
  }

  .videogular__header-controls-icon{
    @include topbar-icon();
  }

  	.videogular__header-controls-icon--subtitles{
  		top: -3px;
  	}

  .videogular__header-subtitles{
  	display: inline-block;
    position: relative;
    padding-right: $global-padding-sm;
    color: $anchor-font-color;
  }

  .videogular__header-subtitles-default-state{
  	opacity: 1;
  	transform: translate(0, 0);
  	@include default-transition();
  }

  .videogular__header-subtitles-default-state--hover{
    opacity: 0;
    transform: translate(0, -1em);
  }    

  .videogular__header-subtitles-options{
    position: absolute;
    white-space: nowrap;
    text-align: right;
    right: 0;
    top: 0;
    transform: translate(0, 1em);
    @include default-transition();
    opacity: 0;
    font-weight: bold;
  }    

  .videogular__header-subtitles-options--hover{
  	transform: translate(0, 0);
  	opacity: 1;
  }

  .videogular__header-subtitles--hover{
  	color: $primary-color;
  }

  vg-controls.videogular__footer-controls {
    bottom: 0; 
    height: $vg-play-diamond-size;

    &:after{
      content: "";
      background-color: $black;
      position: absolute;
      width: 100%;
      height: 50%;
      left: 0;
      bottom: 0;
    }
  }

  vg-controls .controls-container {
  	background: none;
  }

  vg-controls.videogular__footer-controls .controls-container {
    background-color: none;
  }
  
  vg-controls.videogular__footer-time-controls {
    bottom: 0;
    height: ($vg-play-diamond-size / 2) - $vg-scrub-bar-height; 
    right: $global-padding-md;
    text-align: right;

    .videogular__footer-quality-controls {
      vertical-align: middle;
      position: absolute;
      bottom: rem(3);
      right: $global-padding-xl;
      font-family: $font-family-sans-serif;
      font-size: $body-font-size-sm;

      vg-quality-selector ul{
        bottom: $global-padding-sm;
      }
    }
  }

  vg-play-pause-button {
  		width: $vg-play-diamond-size * 1.5;

  		@include breakpoint($screen-sm){
	      width: $vg-play-diamond-size * 2;
	    }

	    &:before{
	      content: "";
	      position: absolute;
	      width: $vg-play-diamond-size * 1.5;
	      height: $vg-scrub-bar-height;
	      top: ($vg-play-diamond-size / 2) - ($vg-scrub-bar-height / 2);
	      left: 0;
	      background-color: $white;
	      z-index: 1;

	      @include breakpoint($screen-sm){
	        width: $vg-play-diamond-size * 2;
	      }
	    }
  }

  .videogular__footer-time-controls vg-time-display {
    display: inline;
  }

  vg-scrub-bar [role=slider] {
  	height: $vg-scrub-bar-height;
  }

  vg-scrub-bar .container {
  	background-color: $gray;
      height: $vg-scrub-bar-height;
      top: ($vg-play-diamond-size / 2) - ($vg-scrub-bar-height / 2);
      padding-left: 0; 
      z-index: 2;
  }

  vg-scrub-bar-cue-points .cue-point-timeline .cue-point {
  		display: none;
  		background: none;

  		@include breakpoint($screen-sm){
  			display: block;
  		}
       &:first-of-type {
          display: none !important;
          &::before {
            display: none !important;
          }
        }
        
  		&::before{
          content: '';
          @include point-of-interest-cue-point($vg-scrub-bar-cue-point-size);
          display: block;
          position: relative;
          bottom: $vg-scrub-bar-cue-point-size * 1.25;
          left: 0;
          transform-origin: bottom center;
          @include default-transition();
        }

        .cue-point__title{
          position: absolute;
          bottom: $vg-scrub-bar-cue-point-size * 1.25;
          left: -($vg-scrub-bar-cue-point-size * 2);
          width: $vg-scrub-bar-cue-point-size * 4;
          text-align: center;
          opacity: 0;
          transform: translate(-$vg-scrub-bar-cue-point-size,0);
          font-family: $font-family-sans-serif;
          @include default-transition();
        }
      }

      vg-scrub-bar-cue-points .cue-point-timeline .cue-point--current {

        &::before{
          transform: rotate(-45deg) scale(1.25);
        }

        .cue-point__title{
          transform: translate(0,0);
          opacity: 1;
        }
      }

    vg-volume {
	    vertical-align: top;
	    position: relative;
	    text-align: right; // text-align: left;
	  }

	 vg-volume-bar {
	    top: 20px;
	    right: 65px; // left: 0;
	    // margin-left: 0;
	 }

	 vg-play-pause-button .play, vg-play-pause-button .pause, vg-overlay-play .play {
      width: $vg-play-diamond-size;
      height: $vg-play-diamond-size;

      &:hover:before{
        background-color: $anchor-font-color-hover;
      }
    }
    vg-play-pause-button .play:before, vg-play-pause-button .pause:before {
      content: ""; 
      position: absolute;
      top: $vg-play-diamond-size / 2;
      left: 0;
      @include diamond($white, none, $vg-play-diamond-size);
      @include default-transition();
    }
  vg-play-pause-button .play:after, vg-play-pause-button .pause:after, vg-overlay-play .play:after{
    color: $black;
    font-size: $vg-play-icon-size;
    @include screen-center;
  }
  vg-mute-button .iconButton{
    width: $header-height;
  }

}