/*
Animations
*/

@mixin default-transition($prop: all) {
	@include transition-property($prop);
	@include transition-duration($default-transition-duration);
	@include transition-timing-function($default-transition-timing);
}

@mixin menu-bg-transition() {
	@include default-transition();
}

@mixin menu-bg(){
	@include breakpoint($screen-max-sm){
		transform: translate($global-padding-lg, 0);
	}
}

@mixin menu-feature-transition() {
	@include transition-property(all);
	@include transition-duration(1s);
	@include transition-timing-function($default-transition-timing);
}

@mixin pattern-bg-transition() {
	@include menu-feature-transition();
}

@mixin pattern-text-transition() {
	@include default-transition();
}

@mixin flip-card-transition() {
	@include transition-property(all);
	@include transition-timing-function($default-transition-timing);
}


@mixin ng-animate-fade($duration){
	opacity:1;

	&.ng-enter, 
	&.ng-leave {
	 	@include transition-property(all);
		@include transition-duration($duration);
		@include transition-timing-function($default-transition-timing);
	}

	&.ng-enter,
	&.ng-leave.ng-leave-active {
	  opacity:0;
	}

	&.ng-leave,
	&.ng-enter.ng-enter-active {
	  opacity:1;
	}
}

@mixin ng-animate-clouds($duration, $zoom){
	opacity:1;

	&.ng-enter{
		@include transition-property(opacity);
		@include transition-duration($duration);
		@include transition-timing-function($default-transition-timing);
	}

	&.ng-leave {
	 	@include transition-property(all);
		@include transition-duration($duration);
		@include transition-timing-function($ease-in-cubic);
	}

	&.ng-enter,
	&.ng-leave.ng-leave-active {
	  opacity:0;
	  transform: scale($zoom);
	}

	&.ng-leave,
	&.ng-enter.ng-enter-active {
	  opacity:1;
	  transform: scale(1);
	}
}

@mixin ng-animate-fade-slide-down($height){
	opacity:1;

	&.ng-enter, 
	&.ng-leave {
	  @include default-transition();
	}

	&.ng-enter,
	&.ng-leave.ng-leave-active {
	  opacity:0;
	  transform: translate(0, $height);
	}

	&.ng-leave,
	&.ng-enter.ng-enter-active {
	  opacity:1;
	  transform: translate(0);
	}
}

@mixin ng-animate-fade-zoom($zoom){
	opacity:1;
	transform: scale(1);

	&.ng-enter, 
	&.ng-leave {
	  	@include transition-property(all);
		@include transition-duration(1s);
		@include transition-timing-function($default-transition-timing);
	}

	&.ng-enter,
	&.ng-leave.ng-leave-active {
	  opacity:0;
	  transform: scale($zoom);
	}

	&.ng-leave,
	&.ng-enter.ng-enter-active {
	  opacity:1;
	  transform: scale(1);
	}
}

//FADE IN IMAGES

.animate-show{
    @include pattern-bg-transition();
    opacity: 0;
}

.animate-show.ng-hide-add, .animate-show.ng-hide-remove.ng-hide-remove-active {
    opacity: 1;
}