.sharebar{
	display: inline-block;
	position: relative;
}

.sharebar__default-state{
	opacity: 1;
	color: $anchor-font-color;
	transform: translate(0, 0);
	@include default-transition();
}

.sharebar__default-state--hide{
	opacity: 0;	
	transform: translate(0, -1em);
}

.sharebar__topbar-icon{
	@include topbar-icon();
}

.sharebar__topbar-icon-label{
	@include topbar-icon-label();
}

.sharebar__share-links{
	position: absolute;
	top: 0;
	width: 100%;
	text-align: center;
	opacity: 0;
	transform: translate(0, 1em);
	@include default-transition();
}

.sharebar__share-links--show{
	opacity: 1;	
	transform: translate(0, 0);
}

.sharebar__share-links-icon{
	width: $header-icon-size;
	height: $header-icon-size;
	vertical-align: bottom;
	fill: $anchor-font-color;
	margin: $global-padding-xs 0 $global-padding-xs;

	@include breakpoint($screen-sm){
		margin: 0 $global-padding-xs/2;
	}
}