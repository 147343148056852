videogular vg-quality {
  display: table-cell;
  width: 50px;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
  color: #fff;
  font-family: $font-family-sans-serif;
  font-size: $body-font-size-sm;
  font-weight: normal;
}

videogular vg-quality div {
  display: block;
}

videogular vg-quality div .vg-auto-quality {
  font-size: 9px;
}

videogular vg-quality vg-quality-selector ul {
  width: 50px;
  bottom: 50px;
  margin: 0;
  padding-left: 0;
  vertical-align: middle;
  text-align: center;
  position: absolute;
  cursor: pointer;
  list-style: none;
}

videogular vg-quality vg-quality-selector ul li {
  line-height: 16px;
  padding: 5px;
  opacity: 0.7;
  background-color: #222;
  margin: 1px 0;
}

videogular vg-quality vg-quality-selector ul li:hover {
  opacity: 1;
}

videogular vg-quality ul li.active {
  background-color: #333;
}
