.poi-360-experience{
	
}

.poi-360-experience__player{
	position: absolute; 
	top: 0; 
	left: 0; 
	width: 100%; 
	height: 100%;	
}

.poi-360-experience__ios-placeholder{
	position: absolute; 
	top: 0; 
	left: 0; 
	width: 100%; 
	height: 100%;	
	@include cover-background('../images/placeholder/360-video.png');
}


.poi-360-experience__mobile{
	@include screen-center;
	// border: 1px solid $primary-color;
	padding: $global-padding-sm;
	width: 75%;
}

.poi-360-experience__mobile-welcome-icon{
	width: 60px;
	height: 60px;
}

.poi-360-experience__mobile-welcome-h1{
	@include welcome-h1;
	margin-top: 0;
	font-size: rem(12);
	white-space: nowrap;
}

.poi-360-experience__mobile-welcome-p{
	@include welcome-p;
	font-size: rem(12);
}

.poi-360-experience__mobile-appstore{
	width: 80px;
}
.poi-360-experience__mobile-more {
	padding-top: 1em;
	padding-bottom: 1em;
}