/*
Colors
*/

$primary-color: 			#357BAB;
$primary-color-dark: 		#1C4058Poit ;
$black:						#141414;
$white:						#fff;
$off-white:					#9D8D87;
$gray: 						#9B9B9B;
$gray-light: 				#595959;
$purple-light: 				#6E7390;
$purple-dark: 				#23172B;
$green-light:				#B8DC9D;
$blue-light:				#A8B0DC;
$red:						#DB4450;
$green:						#0EB160;
$purple:					#6F68AF;
$orange:					#F79225;
$red: 						#DB4450;
$yellow:					#E7BA00;

/*
Typography
*/

$font-color-white: 			$white;
$font-color-gray: 			$gray;
$font-color-gray-light: 	$gray-light;
$font-color-black: 			$black;

$body-font-color:			$font-color-white;
$anchor-font-color:			rgba($font-color-white, 0.7);
$anchor-font-color-hover:	rgba($font-color-white, 1);

$anchor-font-color-invert:			$gray-light;
$anchor-font-color-invert-hover:	$primary-color;

$xs-font-size:				rem(11);
$sm-font-size:				rem(12);
$body-font-size:			rem(16);
$h1-font-size:				rem(42);
$h2-font-size:				rem(28);
$h3-font-size:				rem(24);
$h4-font-size:				rem(20);
$h5-font-size:				rem(16);

$body-font-size-sm:			rem(13);
$h1-font-size-sm:			rem(30);
$h2-font-size-sm:			rem(28);
$h3-font-size-sm:			rem(16);
$h4-font-size-sm:			rem(14);
$h5-font-size-sm:			rem(16);

$font-family-sans-serif:	'Josefin Sans', sans-serif;
$font-family-serif:			'Merriweather', serif;

$body-font-family:			$font-family-serif;
$h1-font-family:			$font-family-sans-serif;
$h2-font-family:			$font-family-sans-serif;
$h3-font-family:			$font-family-serif;
$h4-font-family:			$font-family-serif;
$h5-font-family:			$font-family-sans-serif;

$body-font-weight:			300;
$h1-font-weight:			700;
$h2-font-weight:			300;
$h3-font-weight:			300;
$h4-font-weight:			300;
$h5-font-weight:			300;

$body-line-height: 			1.6;
$h1-line-height:			1.3;
$h2-line-height:			1.6;
$h3-line-height:			1.6;
$h4-line-height:			1.6;
$h5-line-height:			1.6;

$heading-letter-spacing: 	rem(3.43);

/*Info Box*/
$info-box-heading-font-size: rem(22);
$info-box-heading-letter-spacing: 1px;
$info-box-paragraph-font-size: rem(12);
$info-box-icon-size: 16px;


/*Buttons*/
$button-font-color: $white;
$button-font-size: 13px;
$button-padding: 12px 28px 12px 28px;
$button-letter-spacing: $heading-letter-spacing;

/*
Backgrounds
*/

$bg-black: $black;


/*
Padding & Margins
*/

$base-em: 	16px;
$sm-em: 	13px;

$global-padding-xs: 0.5em;
$global-padding-sm: 1em;
$global-padding-md: 2em;
$global-padding-lg: 4em;
$global-padding-xl: 6em;

@mixin no-padding-margin() {
  	margin: 0;
  	padding: 0;
}

$header-padding: 	$global-padding-sm;
$footer-padding: 	$global-padding-sm;

$header-padding-sm: 	$global-padding-xs $global-padding-sm;
$footer-padding-sm: 	$global-padding-xs $global-padding-sm;


/*
Grid
*/

$susy: (
  columns: 12,
  gutters: 1/4,
  math: fluid,
  output: float,
  gutter-position: inside
);

$page-width-full: 1440px;
$page-width: 1040px;
$article-page-width: 640px;

$screen-xs: 480px;
$screen-sm: 768px;
$screen-md: 992px;
$screen-lg: 1200px;

$screen-max-xs: max-width $screen-xs - 1;
$screen-max-sm: max-width $screen-sm - 1;
$screen-max-md: max-width $screen-md - 1;
$screen-max-lg: max-width $screen-lg - 1;

$screen-v-xs: min-height $screen-xs;
$screen-v-sm: min-height $screen-sm;
$screen-v-md: min-height $screen-md;
$screen-v-lg: min-height $screen-lg;

$screen-v-max-xs: max-height $screen-xs - 1;
$screen-v-max-sm: max-height $screen-sm - 1;
$screen-v-max-md: max-height $screen-md - 1;
$screen-v-max-lg: max-height $screen-lg - 1;


$footer-height: 	100px;
$footer-arrow-size: 8px;
$min-body-height: 	$screen-sm - $footer-height;

$screen-sm-w-h: ($screen-v-sm) ($screen-sm);


/*
Header
*/

$header-height: rem(16px);
$header-icon-size: $header-height;
$header-logo-size: rem(92);
$header-logo-size-sm: rem(56);

/*
Navigation
*/

$nav-footer-logo-color: $font-color-gray;
$nav-footer-logo-size: rem(60px);
$nav-footer-logo-padding: $global-padding-md $global-padding-xs 0;
$nav-footer-icon-size: rem(16px);
$nav-footer-icon-padding: $nav-footer-logo-padding;


/*
Footer
*/
$footer-logo-size: $nav-footer-logo-size;
$footer-logo-padding: 0 $global-padding-sm;

/*
Animations
*/

$default-transition-duration: 0.3s;
$default-transition-timing: $ease-out-cubic;

$flip-transition-duration: 1s;
$flip-transition-timing: $ease-in-out-cubic;

$vg-poi-marker-overlay-duration: 2s;

/*
Views
*/

/*HOMEPAGE*/
$homepage-content-width: 640px;

/*EXPLORE AOTEAROA*/
$explore-map-nav-icon-size: 20px;

/*HOW TO POI*/
$how-to-poi-video-nav-size: 60px;

/*ABOUT*/
$about-logo-size: 6rem;
$about-logo-padding: $global-padding-md 0 0;


/*
Elements
*/

$global-list-padding: 0;


/*
Components
*/

/*Icons*/
$quick-links-icon-wrapper-height: 100px;
$quick-links-icon-doco-width: 47px;
$quick-links-icon-360-width: 58px;
$quick-links-icon-map-height: 66px;


/*Video*/
$vg-scrub-bar-cue-point-size: 25px;
$point-of-interest-pointer-size: 50px;

$vg-volume-bar-width: 10px;
$vg-volume-bar-height: 100px;
$vg-volume-bar-color: $white;
$vg-volume-bar-bg: $gray-light;

$vg-play-diamond-size: 80px;
$vg-play-icon-size: 26px;

$vg-play-diamond-size-sm: 40px;
$vg-play-icon-size-sm: 17px;

$vg-scrub-bar-height: 12px;

/*Clouds*/
$cloud-base-size: 100px;

/*Overlays*/
$overlay-close-size: 64px;

/*Flip Cards*/
$card-border-radius: 10px;