.poi-performance{
	@include main-page-padding;
	@include main-page-inner;
}

/*
Intro
*/

.poi-performance__intro{
	padding-bottom: $global-padding-md;	
}

.poi-performance__intro-p{
	font-style: italic;
	max-width: 756px;
	margin: 1em auto;
}