.extended-footer{
	@include container;
	border-top: 1px $gray-light solid;
	border-bottom: 1px $gray-light solid;
	margin-top: $global-padding-xl;
	position: relative;
	padding-bottom: $global-padding-md;
	opacity: 0;

	@include breakpoint($screen-sm){
		&:before{
			content: '';
			box-sizing: border-box;
			position: absolute;
			top: 0;
			left: 0;
			width: 50%;
			height: 100%;
			border-right: 1px $gray-light solid;
		}
	}
}

.extended-footer__quick-links-item{
	text-align: center;
	padding: $global-padding-md 0;
	color: $anchor-font-color;
	position: relative;
	fill: $anchor-font-color;

	@include breakpoint($screen-sm){
		@include span(6);
	}

	&:hover{
		opacity: 1; 
		color: $anchor-font-color-hover;
	}
}

.extended-footer__quick-links-icon-wrapper{
	height: $quick-links-icon-wrapper-height;
}

.extended-footer__quick-links-icon{
	@include valign();
}

.extended-footer__quick-links-icon--doco{
	width: $quick-links-icon-doco-width;	
}

.extended-footer__quick-links-icon--360{
	width: $quick-links-icon-360-width;
}

.extended-footer__quick-links-icon--map{
	height: $quick-links-icon-map-height;
}

.extended-footer__quick-links-p{
	margin: 0;
}