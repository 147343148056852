.press{
	@include main-page-padding;
	@include green-links;
}

.press__inner{
	@include article-page-inner;

	p{
		margin-bottom: $global-padding-md;
	}
}

.press__download{
	border: 1px solid $primary-color;
	padding: $global-padding-md $global-padding-md $global-padding-lg;
	max-width: 432px;
	margin: $global-padding-lg auto $global-padding-lg;
}

.press__download-button{
	margin: 0; 
	
	&:hover{
		text-decoration: none !important;
	}

	span{
		@include button($primary-color);
		@include default-transition('background, color');
	}
}

.press__download-h3{
	@include welcome-h1;
	font-family: $font-family-sans-serif;
	margin-bottom: $global-padding-sm * 1.5;
}

.press__images{
	@include container($page-width-full);
	padding-top: $global-padding-sm;
}

.press__image{
	@include span(6);

	@include breakpoint($screen-sm){
		@include span(3);
	}

	padding: $global-padding-sm * 0.25!important;

	img{
		width: 100%;
		height: auto;
		@include default-transition('border');
		border: 1px solid $black;
		display: block;

		&:hover{
			border: 1px solid $primary-color;			
		}
	}
}