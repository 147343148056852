.volume-bar{
	display: none;
	position: relative;
	padding-right: $global-padding-sm;

  @include breakpoint($screen-sm){
    display: inline-block;
  }
}

.volume-bar__topbar-icon{
	@include topbar-icon();
	font-family: 'videogular';
	font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    &:before{
    	content: "\e002"; 
   	}

   	&.volume-bar__topbar-icon--muted:before{
		content: "\e006"; 
	}
}

.volume-bar__topbar-label{
	@include topbar-icon-label();
}

/*.volume-bar__icon-button{
    color: #FFFFFF;
    font-family: 'videogular';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    background: none;
    border: none;

    &:before{
    	content: "\e002"; 
   	}

   	&.level2:before{
    	content: "\e003"; 
   	}

   	&.level1:before{
    	content: "\e004"; 
   	}

   	&.level0:before{
    	content: "\e005"; 
   	}

   	&.mute:before{
    	content: "\e006"; 
   	}
}
*/