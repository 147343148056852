/*
 * 	Default theme - Owl Carousel CSS File
 */

//nav

$nav-color: 			$anchor-font-color;
$nav-color-hover:		$anchor-font-color-hover;
$nav-font-size: 		$body-font-size;
$nav-rounded: 			3px;
$nav-margin: 			5px;
$nav-padding: 			4px 7px;
$nav-background: 		$anchor-font-color;
$nav-background-hover:	$anchor-font-color-hover;
$nav-disabled-opacity: 	0.5;

//dots

$dot-width:				10px;
$dot-height:			10px;
$dot-rounded:			30px;
$dot-margin: 			5px 7px;
$dot-background:		$anchor-font-color;
$dot-background-active:	$anchor-font-color-hover;

@import 'theme';
