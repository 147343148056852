.explore-map{
	width: 100%;
	height: 100%;
	overflow: auto;
	.cesium-viewer, .cesium-viewer-cesiumWidgetContainer{
		height: 100%;
	}

	.cesium-widget-credits{
		/*height: $header-logo-size-sm;*/
		padding: $global-padding-xs;
		font-size: rem(9);
		opacity: 0.5;
	}

	.cesium-widget, .cesium-widget canvas{
		display: block;
	}
}

.explore-map__nav{
	text-align: right;
	z-index: 10;
	text-transform: capitalize;
	position: absolute;
	top: $global-padding-xl + 2;

	@include calc(right, "(#{$global-padding-sm})");
	@include breakpoint($screen-sm){
		right: -0.7em;
		// @include valign-abs;
		position: absolute;
		top: 54%;
		-webkit-transform: translateY(-50%);
    	transform: translateY(-50%);
	}
}

.explore-map__nav-item{
	height: 25px;
	font-weight: bold;
	position: relative;
	&:first-of-type {
		display: none;
	}
}

.explore-map__nav-anchor{
	height: 100%;
	position: relative;
	@include clearfix;

	&:after{
		content: '';
		@include valign-abs;
		right: $explore-map-nav-icon-size * 0.35;

		// @include diamond(none, 1px solid $white, $explore-map-nav-icon-size);
	}

	&:hover{
		.explore-map__nav-item-label{
			opacity: 1;
			color: $black;
			/*transform: translate(-($explore-map-nav-icon-size), 0);*/
			@include breakpoint($screen-sm) {
				color: $white;
			}
		}		

		// &:after{
		// 	@include diamond($white, none, $explore-map-nav-icon-size);
		// }
	}
}

.explore-map__nav-anchor--active{
	.explore-map__nav-item-label{
		opacity: 1;
		color: $black;
		/*transform: translate(-($explore-map-nav-icon-size), 0);*/
		@include breakpoint($screen-sm) {
			color: $white;
		}

	}

	&:after{
		display: none;
		@include breakpoint($screen-sm) {
			display: block;
			@include diamond($white, none, $explore-map-nav-icon-size);
		}
	}
}

.explore-map__nav-item-label{
	color: $white;
	opacity: 0.5;
	position: absolute;
	white-space: nowrap;
    top: -($explore-map-nav-icon-size * 0.35);
    right: 0;
	vertical-align: middle;
	/*transform: translate(0);*/
	transform: translate(-($explore-map-nav-icon-size), 0);
	/*@include default-transition();*/
	padding-right: $explore-map-nav-icon-size * 0.7;
	display: none;

	@include breakpoint($screen-sm){
		display: block;
	}
}

.explore-map__welcome-tooltip{
	z-index: 1;
	position: absolute;
	@include span(3);
	top: 30%;
	left: 10%;
	display: none;

	@include breakpoint($screen-sm){
		display: block;
	}
}

.explore-map__welcome-tooltip-h1{
	@include welcome-h1;
}

.explore-map__welcome-tooltip-p{
	@include welcome-p;
}

.explore-map__welcome-tooltip-icon{
	position: relative;
	color: $white;
	font-family: $font-family-serif;

	&:before{
		content: '';
		position: absolute;
		@include diamond($primary-color, none, 26px);
		z-index: -1;
	    top: 6px;
    	left: -10px;
	}
}

.mobile-menu-active {
	width: 100%;
	height: 100%;
	background-color: $white;
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	overflow-y: scroll;
   -webkit-overflow-scrolling: touch;
	text-align: left;
	box-sizing: border-box;
	padding: 3em 2em;
	z-index: 150;

	.mobile-map-menu__close {
		position: absolute;
		right: 1em;
		top: 1em;
		height: 25px;
    	width: 25px;
    	cursor: pointer;
    	z-index: 160;
		&:before, &:after {
			content: '';
			position: absolute;
			width: 2px;
			height: 100%;
			top: 50%;
			left: 50%;
			background-color: $black;
		}

		&:before {
			-ms-transform: rotate(-45deg) translate(-50%, -50%);
			-webkit-transform: rotate(-45deg) translate(-50%, -50%);
			transform: rotate(-45deg) translate(-50%, -50%);
		    transform-origin: 0 0;
		}

		&:after {
			-ms-transform: rotate(45deg) translate(-50%, -50%);
			-webkit-transform: rotate(45deg) translate(-50%, -50%);
			transform: rotate(45deg) translate(-50%, -50%);
		    transform-origin: 0 0;
		}
	}

	.spacer {
		height: 50px;
		display: none;
	}

	.explore-map__nav-item {
		margin-bottom: .5em;
		a {
			span {
				color: #141414;
			}
		}
	}

	.explore-map__nav-item-label{
		display: inline-block;
		left: 0;
		transform: translate(0);
		padding-left: 30px;
	}

	.explore-map__nav-anchor{
		display: inline-block;
		line-height: 30px;
		&:before{
			content: '';
			position: absolute;
			top: 7px;
			left: 0;
			opacity: .7;
			@include diamond(none, 1px solid $gray, $explore-map-nav-icon-size);
		}
	}
	.explore-map__nav-anchor--active {
		&:before{
			opacity: 1;
			@include diamond($black, none, $explore-map-nav-icon-size);
		}
	}

	.mobile-map-menu__copy {
		color: $black;
		display: block;
		.mobile-map-menu__heading {
			text-transform: none;
			font-size: 1.76rem;
			letter-spacing: 0;
		}
		.mobile-map-menu__text {
			margin-top: .5em;
			margin-bottom: 2.25em;
		}
	}
}

.mobile-map-menu__copy {
	display: none;
}

.map-menu-icon {
	@include diamond($white, none, 65px);
	// background-color: $white;
	position: absolute;
	bottom: 3em;
	right: 3em;
	z-index: 99;
	cursor: pointer;
	@include breakpoint($screen-sm) {
		display: none;
		visibility: hidden;
	}	
	img {
		width: 75%;
		height: 75%;
		position: absolute;
		left: 50%;
		top: 50%;
		-ms-transform: rotate(45deg) translate(-50%, -50%);
		-webkit-transform: rotate(45deg) translate(-50%, -50%);
		transform: rotate(45deg) translate(-50%, -50%);
	    transform-origin: 0 0;
	}
}


.explore-map__clouds{
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
    overflow: hidden;
    pointer-events:none;
    @include ng-animate-clouds(1.5s, 30);

    display: none;

	@include breakpoint($screen-sm){
		display: block;
	}
}

.explore-map__cloud{
	position: absolute;
	z-index: 1;
	pointer-events:none;
	background-size: 100%;
	/*background-position: center center;*/
}

.explore-map__cloud-1{
	background-image: url(../images/cloud-26.png);
	background-position: -240px -100px;
	width: 130%;
	height: 130%;
	opacity: 0.8;
	@include animation(animate-cloud-1 300s linear infinite);
}

.explore-map__cloud-2{
	background-image: url(../images/cloud-32.png);
	width: 100%;
	height: 100%;
	opacity: 0.25;
	@include animation(animate-cloud-2 300s linear infinite);
}

@-webkit-keyframes animate-cloud-1 {
  0%   { transform: translate(0) scale(1); }
  50% { transform: translate(-20%,0); }
  100% { transform: translate(0); }
}

@-webkit-keyframes animate-cloud-2 {
  0%   { transform: translate(30%,0) scale(1); }
  50% { transform: translate(-30%,0); }
  100% { transform: translate(30%,0); }
}