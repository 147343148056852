.about{
	@include main-page-padding;
	@include green-links;
}

.about__inner{
	@include article-page-inner;
}

.about__logo{
	width: $about-logo-size;
	height: $about-logo-size;
	margin: $about-logo-padding;
	fill: $anchor-font-color;
}