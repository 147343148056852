/*
Main
*/

@mixin main-page-padding{
	@include calc(padding-top, "#{$header-logo-size-sm} + 2");
	padding-left: $global-padding-sm;
	padding-right: $global-padding-sm;

	@include breakpoint($screen-sm){
		@include calc(padding-top, "#{$header-logo-size} + 5");
	}
}

@mixin main-page-inner{
	max-width: $page-width;
	margin: auto;
}

@mixin article-page-inner{
	max-width: $article-page-width;
	margin: auto;
	padding: 0 $global-padding-sm;
}

@mixin loading{
	opacity: 0;
}


/*
Helpers
*/

@mixin valign(){
	position: relative;
	top: 50%;
	transform: translateY(-50%);
}

@mixin valign-abs{
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
}

@mixin halign-abs{
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
}

@mixin screen-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin aspect-ratio($width, $height) {
  position: relative;
  &:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: ($height / $width) * 100%;
  }
  > .aspect-ratio-content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

/*
Header
*/

@mixin topbar-icon(){
	height: $header-icon-size;
	width: $header-icon-size;
	fill: $anchor-font-color;
	vertical-align: middle;
	top: $header-icon-size / 4;
	left: 0;

	@include breakpoint($screen-sm){
		position: absolute;
		/*margin-right: $global-padding-xs;*/
	}
/*
	path {
		fill: $body-font-color;
	}*/
}

@mixin topbar-icon-label(){
	display: none;

	@include breakpoint($screen-sm){
		display: inline;
		font-weight: bold;
		@include calc(margin-left, #{$header-icon-size} + #{$global-padding-sm});
	}
}

@mixin topbar-list-item(){
	padding: 0 $global-padding-xs;
    display: inline;
    position: relative;

    @include breakpoint($screen-sm){
        padding: 0 $global-padding-xs;
    }
}

@mixin header-height{
	
}

@mixin topbar-controls-wrapper{
	position: absolute;
	top: 0;
	text-align: right;
    max-width: 230px;
    right: 64px;

    @include breakpoint($screen-sm){
      right: 120px;
      padding: $header-padding 0;
    }
}



/*
Icons
*/

@mixin point-of-interest-icon($size){
	width: $size;
	height: $size;
	background-image: url(../svgs/poi-diamond-icon.svg);
	background-size: 100%;

	&:hover{
		background-image: url(../svgs/poi-diamond-icon-blue.svg);
	}
}

@mixin point-of-interest-cue-point($size){
	width: $size;
	height: $size;
	@include diamond($white, none, $size);
}

@mixin close-icon{
	content: '\00d7';
	position: relative;
	left: -0.15em;
	top: 0.15em;
	font-size: 2.5em;
	font-family: $font-family-serif;
}

@mixin close-icon-diamond(){

	font-size: rem(20);

	@include breakpoint($screen-max-sm){
		font-family: $font-family-serif;
		position: relative;
		width: 100%;
		height: $overlay-close-size;
		z-index: 30;
		text-align: center;
	}
	
    /*top: $global-padding-sm;*/

    a{
    	position: absolute;
    	z-index: 3;

    	@include breakpoint($screen-max-sm){
    		width: 100%;
		    height: $overlay-close-size;
		    line-height: $overlay-close-size;
		    padding-top: $global-padding-xs / 2;
		    top: 0;
		    left: 0;
		    background-color: $black;
    	}

    	@include breakpoint($screen-sm){
    		width: inherit;
	    	background-color: unset;
	        top: -6px;
    		right: 19px;	

	    	&:before{
				content: '';
				position: absolute;
				@include diamond($black, none, $overlay-close-size);
				z-index: 0;
				top: 6px;
				left: -13px;
			}
    	}	
    }

    .info-box__modal-close-anchor--icon{
    	display: none;

    	@include breakpoint($screen-sm){
    		display: block;
    		position: absolute;
    		top: -7px;
    		left: 11px;
    		width: 100%;
    	}
    }

    .info-box__modal-close-anchor--text{
    	display: block;

    	@include breakpoint($screen-sm){
    		display: none;
    	}
    }
}

@mixin diamond($bg-color, $border, $size){
	width: $size * 0.7;
	height: $size * 0.7;
	background-color: $bg-color;
	border: $border;
	-ms-transform: rotate(-45deg);
	-webkit-transform: rotate(-45deg);
	transform: rotate(-45deg);
    transform-origin: 0 0;
}

@mixin diamond-bottom-border($bg-color, $border, $size){
	width: $size * 0.7;
	height: $size * 0.7;
	background-color: $bg-color;
	border-bottom: $border;
	border-left: $border;
	-ms-transform: rotate(-45deg);
	-webkit-transform: rotate(-45deg);
	transform: rotate(-45deg);
    transform-origin: 0 0;
}

@mixin circle($size, $border, $fill){
	border-radius: 50%;
	width: $size;
	height: $size;
	background: $fill;
	border: $border; 
}

@mixin play-button-overlay($diamond-size, $play-icon-size){
	font-family: 'videogular';
	src: url("/fonts/videogular.eot");
	src: url("/fonts/videogular.eot?#iefix") format("embedded-opentype"), url("/fonts/videogular.woff") format("woff"), url("/fonts/videogular.ttf") format("truetype"), url("/fonts/videogular.svg#videogular") format("svg");

    &:before{
    	content: ""; 
	    @include screen-center;
	    @include diamond($white, none, $diamond-size);
	    @include default-transition();
	    margin-left: -($diamond-size / 2);
    }

    &:after{
    	content: "\e000";
    	font-size: $play-icon-size;
	    color: $black;
	    z-index: 1;
	    @include screen-center;
	    margin-left: 2px;

	    @include breakpoint($screen-sm){
    		margin-left: 3px;
    	}
    }
}


/*
Typography
*/

@mixin text-sm-up-sans{
	font-size: $sm-font-size;
	text-transform: uppercase;
	font-family: $font-family-sans-serif	
}

@mixin info-heading($bg-color){
	background-color: $bg-color;
	padding: 15px $global-padding-md;
	position: relative;

	&:before, &:after{
		content: '';
		width: 100%;
		height: 8px;
		background-size: 8px;
		position: absolute;
		background-image: url(../images/triangle-tile-pattern@2x.png);
	}

	&:before{
		transform: rotate(180deg);
		top: 0;
		left: 0;
	}

	&:after{
		bottom: 0;
		left: 0;
	}

	h2{
		font-size: $info-box-heading-font-size;
		letter-spacing: $info-box-heading-letter-spacing;	
		margin: 0;
	}
}

@mixin green-links{
	a{color: $primary-color;}
	a:hover{text-decoration: underline;}
}

@mixin button($color){
	font-family: $font-family-sans-serif;
	text-transform: uppercase;
	color: $color;
	font-size: $button-font-size;
	padding: $button-padding;
	border: 2px $color solid;
	letter-spacing: $button-letter-spacing;
	text-decoration: none;
	text-align: center;
	white-space: nowrap;

	&:hover {
	  text-decoration: none;
	  background-color: $color;
	  color: $font-color-black;
	}
}

/*
Backgrounds
*/

@mixin overlay-bg($opacity){
	width: 100%;
	height: 100%;
	background-color: rgba($black, $opacity);
}

@mixin video-bg($img-uri: none){
	position: fixed;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: -100;
    transform: translateX(-50%) translateY(-50%);
    background-size: cover; 

    @if $img-uri == none {
	    
	} @else {
	    background: url($img-uri) no-repeat;
	}
}

@mixin cover-background($img-uri){
  background: url($img-uri) no-repeat center center; 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;  
}

@mixin bg-pattern{
	&:after{
    	content: '';
    	position: absolute;
    	width: 100%;
    	height: 100%;
    	top: 0;
    	left: 0;
    	opacity: 0.3;
    	background-size: contain;
	    background-position: center;
	    background-repeat: no-repeat;
	    background-image: url('../images/pattern-bg.png');		
	    z-index: -1;
    }
}

@mixin bg-cloud{
	&:before{
    	content: '';
    	position: absolute;
    	width: 100%;
    	height: 100%;
    	top: 0;
    	left: 0;
    	opacity: 0.22;
    	background-size: contain;
	    background-position: center;
	    background-repeat: no-repeat;
	    background-image: url('../images/cloud-32.png');		
	    z-index: -1;
    }
}

@mixin bg-wrapper($opacity){
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	opacity: $opacity;
    z-index: -1;

    img{
    	@include screen-center;
		max-width: 100%;
		max-height: 100%;
    }
}

/*
Components
*/

@mixin tile-wrapper{
	opacity: 0;
	position: relative;
	margin-bottom: $global-padding-xs;
	cursor: pointer;

}

@mixin welcome-h1{
	font-size: rem(18);
	text-transform: uppercase;
	letter-spacing: $heading-letter-spacing;
	margin-top: $global-padding-sm;
	margin-bottom: $global-padding-xs;
}

@mixin welcome-p{
	margin-top: 0;
	font-style: italic;
}