.page-background{
	position: fixed;
	height: 100%;
	width: 100%;
	z-index: -1;
}

.page-background--home{
	@include video-bg("../images/bg-home.jpg");
}

.page-background--black{
	@include background($black);
}