.poi-flashcards{
	@include main-page-padding;
	@include main-page-inner;
}

.poi-flashcards__intro-p{
	font-style: italic;
	padding: 0 25px;
	@include breakpoint($screen-xs){
		padding: 0 75px;
	}
	@include breakpoint($screen-sm){
		padding: 0 125px;
	}
}

.poi-flashcards__cards{
	padding-top: $global-padding-md;
	@include container;
	max-width: $screen-xs;

	@include breakpoint($screen-md){
		max-width: 100%;
	}
}

.poi-flashcards__card-wrapper{
	width: 100%;
	height: 427px;
	perspective: 800;
	perspective-origin: 50% 50% 0px;
	@include border-top-radius($card-border-radius);
	@include border-right-radius($card-border-radius);
	@include border-bottom-radius($card-border-radius);
	@include border-left-radius($card-border-radius);

	@include tile-wrapper;
	@include default-transition();
	
	@include breakpoint($screen-md){
		@include span(4);
		padding: 0 $global-padding-xs / 2;
	}
}


.poi-flashcards__card-flipper{
	/*@include flip-card-transition();*/
	transform-style: preserve-3d;
	position: relative;
}

.poi-flashcards__card-inner{
	padding: 0 $global-padding-lg;
}

.poi-flashcards__card-front, .poi-flashcards__card-back{
	width: 100%;
	height: 427px;
	@include border-top-radius($card-border-radius);
	@include border-right-radius($card-border-radius);
	@include border-bottom-radius($card-border-radius);
	@include border-left-radius($card-border-radius);
	backface-visibility: hidden;
	transform-style: preserve-3d;
    position: absolute;
    top: 0;
	left: 0;
}

.poi-flashcards__card-img{
	width: auto;
	height: auto;
	max-width: 90%;
	max-height: 90%;
}

.poi-flashcards__card-front{
	background-color: #000;
	z-index: 2;
	transform: rotateY(0deg);
}

.poi-flashcards__card-back{
	@include linear-gradient(to bottom, $primary-color, $primary-color-dark);
	@include bg-pattern;
	transform: rotateY(180deg);
}

.poi-flashcards__card-english{
	position: absolute;
	width: 100%;
	bottom: $global-padding-sm;
	text-transform: uppercase;
}

.poi-flashcards__card-te-reo{
	position: absolute;
	width: 80%;
	margin: 0;
	font-style: italic;
	@include screen-center;
		//margin-top: $global-padding-md;
	

	p{
		font-size: rem(20);	
	}
}

.poi-flashcards__tepapa{
	position: absolute;
	bottom: $global-padding-md;
	right: $global-padding-md;
	width: 29px;
	height: 61px;
	opacity: 0.3;
}

/*
Animations
*/

.poi-flashcards__card-flipper{
	&.ng-enter-stagger {
	  /* 200ms will be applied between each sucessive enter operation */ 
	  -webkit-transition-delay:0.2s;
	  transition-delay:0.2s;

	  /* this is here to avoid accidental CSS inheritance */
	  -webkit-transition-duration:0;
	  transition-duration:0;
	}

	&.ng-enter {
	  -webkit-transition:0.5s linear all;
	  transition:0.5s linear all;

	  opacity:0;
	}

	&.ng-enter.ng-enter-active {
	  opacity:1;
	}
}