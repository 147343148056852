/*
Defaults
*/
body,html{
	height: 100%;
	color: $body-font-color;
	font-family: $body-font-family;
	font-weight: $body-font-weight;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}


html {
    /*overflow: hidden;*/
}

body{
	/*@include cover-background("/images/bg-home.jpg");*/
	/*@include background(circle at 50% 0%, $purple-light, $purple-dark);*/
	/*@include background(linear-gradient($purple-light, $purple-dark) left repeat);*/
	background-color: $black;
	/*overflow: auto;*/
}



ul, li{
	list-style: none;
	margin: $global-list-padding;
	padding: $global-list-padding;
}

p{
	font-size: $body-font-size-sm;

	@include breakpoint($screen-sm){
		font-size: $body-font-size;
	}
}

/*
Helpers
*/

.pn{padding: 0 !important;}
.mn{margin: 0 !important;}

.ptn{padding-top: 0 !important;}
.prn{padding-right: 0 !important;}
.pbn{padding-bottom: 0 !important;}
.pln{padding-left: 0 !important;}

.pts{padding-top: $global-padding-sm;}
.prs{padding-right: $global-padding-sm;}
.pbs{padding-bottom: $global-padding-sm;}
.pls{padding-left: $global-padding-sm;}

.ptm{padding-top: $global-padding-md;}
.prm{padding-right: $global-padding-md;}
.pbm{padding-bottom: $global-padding-md;}
.plm{padding-left: $global-padding-md;}

.ptl{padding-top: $global-padding-lg;}
.prl{padding-right: $global-padding-lg;}
.pbl{padding-bottom: $global-padding-lg;}
.pll{padding-left: $global-padding-lg;}

.mtn{margin-top: 0 !important;}
.mrn{margin-right: 0 !important;}
.mbn{margin-bottom: 0 !important;}
.mln{margin-left: 0 !important;}
.mn{margin: 0 !important;}

.mts{margin-top: $global-padding-sm;}
.mrs{margin-right: $global-padding-sm;}
.mbs{margin-bottom: $global-padding-sm;}
.mls{margin-left: $global-padding-sm;}

.mtm{margin-top: $global-padding-md;}
.mrm{margin-right: $global-padding-md;}
.mbm{margin-bottom: $global-padding-md;}
.mlm{margin-left: $global-padding-md;}

.mtl{margin-top: $global-padding-md;}
.mrl{margin-right: $global-padding-lg;}
.mbl{margin-bottom: $global-padding-lg;}
.mll{margin-left: $global-padding-lg;}

.align-baseline { vertical-align: baseline }
.align-top      { vertical-align: top }
.align-middle   { vertical-align: middle }
.align-bottom   { vertical-align: bottom }

.inline{display: inline;}
.ul-inline li{display: inline;}
.hide{display: none;}


/*
Typography
*/

.text-white{color: $font-color-white};
.text-gray{color: $font-color-gray};
.text-gray-light{color: $font-color-gray-light};

.text-left{text-align: left;}
.text-right{text-align: right;}
.text-center{text-align: center;}
.text-xs{font-size: $xs-font-size;}
.text-sm{font-size: $sm-font-size;}
.text-em{font-style: italic;}
.text-up{text-transform: uppercase;}
.text-serif{font-family: $font-family-serif}
.text-sans-serif{font-family: $font-family-sans-serif}
.text-sm-up-sans{
	@include text-sm-up-sans;
}
.text-up-sans-13{
	text-transform: uppercase;
	font-family: $font-family-sans-serif;
	font-size: rem(13);
}

a{
	color: $anchor-font-color;
	text-decoration: none;
	@include default-transition();

	svg{@include default-transition();}

	&:hover{
		color: $anchor-font-color-hover;

		svg{fill: $anchor-font-color-hover;}
		button{color: $anchor-font-color-hover;}
	}
}

h1, .h1{
	font-size: $h1-font-size-sm; 
	font-family: $h1-font-family;
	font-weight: $h1-font-weight;
	text-transform: uppercase;
	line-height: $h1-line-height;
	letter-spacing: $heading-letter-spacing;
	margin: 0;

	@include breakpoint($screen-sm){
		font-size: $h1-font-size; 
	}
}
h2, .h2{
	font-size: $h2-font-size-sm; 
	font-family: $h2-font-family;
	font-weight: $h2-font-weight;
	line-height: $h2-line-height;

	@include breakpoint($screen-sm){
		font-size: $h2-font-size; 
	}
}
h3, .h3{
	font-size: $h3-font-size-sm; 
	font-family: $h3-font-family;
	font-weight: $h3-font-weight;
	line-height: $h3-line-height;

	@include breakpoint($screen-sm){
		font-size: $h3-font-size; 
	}
}
h4, .h4{
	font-size: $h4-font-size-sm; 
	font-family: $h4-font-family;
	font-weight: $h4-font-weight;
	line-height: $h4-line-height;

	@include breakpoint($screen-sm){
		font-size: $h4-font-size; 
	}
}
h5, .h5{
	font-size: $h5-font-size-sm; 
	font-family: $h5-font-family;	
	font-weight: $h5-font-weight;
	line-height: $h5-line-height;

	@include breakpoint($screen-sm){
		font-size: $h5-font-size; 
	}
}

p, ul, li{
	line-height: $body-line-height;
}

/*
Backgrounds
*/

.bg-black{background-color: $black;}
.overlay{
	@include overlay-bg(0.5);
	position: fixed;
	z-index: 101;
}

.bg-pattern__wrapper{
	@include bg-wrapper(0.1);
}

.bg-clouds__wrapper{
	@include bg-wrapper(0.2);
}

/*
Images
*/

.responsive-image{
	width: 100%;
	height: auto;
}