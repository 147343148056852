.vg-poi-marker-overlay__marker{
	@include point-of-interest-icon($point-of-interest-pointer-size);
	position: absolute;
	z-index: 80;
	cursor: pointer;
	display: none;
	border: none !important;
	
	@include breakpoint($screen-sm){
		display: block;
	}

	&:before, &:after{
		content: '';
		position: absolute;
		border: 1px solid $white;
	    width: 100%;
	    height: 100%;
	    transform: scale(0) rotate(45deg);
	    @include animation-name(point-of-interest-marker-keyframes);
		@include animation-duration($vg-poi-marker-overlay-duration);
		@include animation-timing-function(linear);
		@include animation-iteration-count(infinite);
		z-index: 70;
	}

	&:after{
		@include animation-delay($vg-poi-marker-overlay-duration / 2);
	}
}


@keyframes point-of-interest-marker-keyframes {
    0% {transform: scale(1) rotate(45deg); opacity: 0.3;}
    100% {transform: scale(1.75) rotate(45deg); opacity: 0;}
}

.vg-poi-marker-overlay__modal{
	background: rgba($black, 1); 
	z-index: 310;
    @include halign-abs();
    @include container();
    width: 100%; 

    @include breakpoint($screen-sm){
    	top: $global-padding-lg;
    	padding-top: $global-padding-sm;
    	padding-bottom: $global-padding-sm;
    	width: 95%;
    	@include screen-center;
    	z-index: 102;
    }

    @include breakpoint($screen-md){
    	@include calc(width, "#{$screen-md} - #{$global-padding-lg}");
    }
}

.vg-poi-marker-overlay__modal--open{
	display: block;	
}

.vg-poi-marker-overlay__modal-close{
    @include close-icon-diamond();
}

.vg-poi-marker-overlay__modal-media{

	@include breakpoint($screen-sm){
		padding: 0 $global-padding-md 0 $global-padding-sm !important;
    	float: left;
		max-width: 50%;
		min-width: 50%;
    }	

    @include breakpoint($screen-md){
    }	
}

.vg-poi-marker-overlay__modal-heading-h2{
	font-size: $info-box-heading-font-size;
	letter-spacing: $info-box-heading-letter-spacing;

	@include breakpoint($screen-sm){
		margin-top: 0;
	}
}

.vg-poi-marker-overlay__modal-content{
	padding: 0 $global-padding-sm !important;

	@include breakpoint($screen-sm){
		padding: $global-padding-sm !important;
    }

    @include breakpoint($screen-md){
    }
}

	.vg-poi-marker-overlay__modal-message{
		font-size: $info-box-paragraph-font-size;	

		p{
			font-size: $info-box-paragraph-font-size;
		}
	}