/*
Page Header
*/

.header{
	width: 100%;
	position: absolute;
	z-index: 150;
}

.header__inner{
	@include container;
	padding: $header-padding-sm;

	@include breakpoint($screen-sm){
		padding: $header-padding;
	}
}

.header--menu-open{
	@include menu-bg();
}

/*
Topbar
*/

.header__topbar-menu, .header__topbar-share{
	height: $header-height;
	@include calc('padding-top', ($header-logo-size-sm / 2) - ($header-height));
	@include span(4);

	@include breakpoint($screen-sm){
		@include calc('padding-top', ($header-logo-size / 2) - ($header-height));
	}
}

.header__topbar-list-anchor{
	position: relative;  	
}

.header__topbar-logo{
	@include span(4);
}

.header__topbar-logo-icon{
	height: $header-logo-size-sm;
	fill: $anchor-font-color;

	@include breakpoint($screen-sm){
		height: $header-logo-size;
	}
}

.header__topbar-icon{
	@include topbar-icon();
}

.header__topbar-icon-label{
	@include topbar-icon-label();
}

.header__topbar-list-item{
	@include topbar-list-item();	
}


/*
Menu
*/

.header__menu{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 510;
	overflow: hidden;

	@include breakpoint($screen-sm){
		top: $global-padding-md;
		left: $global-padding-md;
		@include calc("max-width", "#{$screen-sm} - #{$global-padding-lg}");
	}

	@include breakpoint($screen-md){
		@include calc("max-width", "#{$screen-md} - #{$global-padding-lg}");
	}
}

.header__menu--open{
	@include breakpoint($screen-sm){
		;
	}
}

.header__menu-inner{
	padding: $global-padding-lg $global-padding-md;

	@include breakpoint($screen-sm){
		padding: $global-padding-lg $global-padding-lg;
	}

	@include breakpoint($screen-md){
		// padding: $global-padding-lg $global-padding-xl $global-padding-lg $global-padding-lg * 2;
		padding: 2.5em 6em 2.5em 7em;
	}
}

.header__menu-bg{
    position: absolute;
    display: block;
    top: 0;
    z-index: -1;
    background: $white;
    width: 100%;
    height: 150%;
    transform-origin: top right;
}

/*
Menu main content
*/

.header__menu-main-title{
	color: $gray;
}

.header__menu-main{
	@include container;
	margin-bottom: $global-padding-sm;
}

.header__menu-left{
	@include breakpoint($screen-sm){
		@include span(6);
	}
}

.header__menu-main-list-item{
	margin: $global-padding-xs 0;
	font-size: $h2-font-size;
	font-weight: bold;

	a{color: $black;}
	a:hover{color: $anchor-font-color-invert-hover;	}
}

/*
Menu main content - right column
*/

.header__menu-right{
	display: none;

	@include breakpoint($screen-sm){
		display: block;
		@include span(6);
	}
}

.header__menu-hover-feature{
	margin-top: $global-padding-xl;
	opacity: 0;
	width: 100%;
	height: 300px;
	position: relative;
	@include menu-feature-transition(); 

	&:after{
		content: "";
		background-size: 120% 120%;
	    background-repeat: no-repeat;
	    background-image: url('../images/pattern-bg-grey.png');	
	    background-position: center;
		opacity: 0.4;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		position: absolute;
		z-index: -1;
	}
}

.header__menu-hover-feature--show{
	opacity: 1;	
}

.header__menu-hover-feature-text{
	color: $primary-color;
	font-style: italic;
	@include valign(); 
}


/*
Menu Footer
*/

.header__menu-footer{
	@include container;
}

.header__menu-footer-list-column, .header__menu-footer-icon-column, .header__menu-footer-logo-column{
	@include breakpoint($screen-sm){
		@include span(4);
	}
}

.header__menu-footer-icon-column-inner, .header__menu-footer-logo-column-inner{
	/*position: absolute;
	bottom: 0;*/
}

.header__menu-footer-list-column{
	
}

.header__menu-footer-icon-column{
	text-align: center;
	@include breakpoint($screen-sm){
		text-align: right;
		@include isolate(5);
	}
}

.header__menu-footer-logo-column{
	text-align: center;
	@include breakpoint($screen-sm){
		text-align: left;
	}
}

.header__menu-footer-list{

}

.header__menu-footer-list-item{
	margin: $global-padding-xs 0;
}

.header__menu-footer-logo{
	height: $nav-footer-logo-size;
	width: $nav-footer-logo-size;
	margin: $nav-footer-logo-padding;
	vertical-align: top;
	fill: $nav-footer-logo-color;
}

.header__menu-footer-icon{
	height: $nav-footer-icon-size;
	max-width: $nav-footer-icon-size * 1.5;
	margin: $nav-footer-icon-padding;
	@include calc(padding-top, "#{$global-padding-sm} + (#{$nav-footer-logo-size} / 8)"); /*Offset against logos*/
	vertical-align: top;
	fill: $nav-footer-logo-color;
}

.header__menu-footer-anchor{
	color: $anchor-font-color-invert;
	
	&:hover{
		color: $anchor-font-color-invert-hover;

		.header__menu-footer-logo, .header__menu-footer-icon{
			fill: $anchor-font-color-invert-hover;
		}
	}
}

/*
Menu Close
*/

.header__menu-close-icon{
	width: $header-icon-size;
	height: $header-icon-size;
	fill: $anchor-font-color-invert;
	vertical-align: middle;
	position: absolute;
	top: $global-padding-md;
	left: $global-padding-md;

	&:hover{
		fill: $anchor-font-color-invert-hover;
	}
}