.learn-how-to-poi{
	@include main-page-padding;
	@include main-page-inner;
}

/*
Intro
*/

.learn-how-to-poi__intro{
	padding-bottom: $global-padding-md;
}

.learn-how-to-poi__intro-p{
	font-style: italic;
}