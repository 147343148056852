.interviews-profiles-previews{
	padding-top: $global-padding-md;
	@include container;
	max-width: $screen-xs;
	z-index: 1;

	@include breakpoint($screen-md){
		max-width: 100%;
		display: flex;
		flex-flow: row wrap;
		justify-content: flex-start;
	  	align-items: flex-start;
	}
}

.interviews-profiles__intro-p{
	font-style: italic;
}

.interviews-profiles-preview__wrapper-anchor{
	color: inherit;

	&:hover{
		color: inherit;
	}

	@include breakpoint($screen-md){
		@include calc(width, "98% / 3");
		@include calc('margin-left', "2% / 6");
		@include calc('margin-right', "2% / 6");
	}
}

.interviews-profiles-preview__wrapper{
	@include tile-wrapper;
	background-color: $black;
	cursor: inherit;
	height: 100%;
	padding-bottom: $global-padding-sm;

	/*@include default-transition();

	&:hover{
		.interviews-profiles-preview__heading{
			background-color: $red;
		}		
	}*/
}

.interviews-profiles-preview__heading{
	@include info-heading($primary-color);
	@include default-transition;
}

.interviews-profiles-preview__p{
	padding: $global-padding-sm $global-padding-md;

	p{font-size: $info-box-paragraph-font-size;}
}

.interviews-profiles-preview__button{
	/*margin: $global-padding-lg 0 $global-padding-md;*/
	/*text-align: center;*/
	/*position: absolute;*/

	span{
		@include button($primary-color);
		@include default-transition;
		padding-right: 25px;
	}
}