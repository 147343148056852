/*
Footer
*/

.footer{
	width: 100%;	
	bottom: 0;
	background-color: $bg-black; 

	@include breakpoint($screen-v-max-sm){
		position: relative;
	}

	@include breakpoint($screen-sm){
		position: relative;
    	margin-top: $global-padding-sm;
    	background-color: transparent; 
   	}

   	@include breakpoint($screen-max-sm){
   		&:before{
	   		content: '';
			width: 100%;
			height: $footer-arrow-size;
			background-size: $footer-arrow-size;
			top: -$footer-arrow-size;
			position: absolute;
			background-image: url(../images/triangle-tile-pattern@2x.png);
	   	}
   	}
}

.footer__inner{
	padding: $footer-padding;
	@include container;
}


.footer__logos-column, .footer__copyright-column, .footer__links-column{
	text-align: center;
	padding: $global-padding-sm 0;

	@include breakpoint($screen-sm){
		@include span(4);
		padding: 0;
	}
}

.footer__copyright-column-inner{
	@include breakpoint($screen-sm){
		@include screen-center;	
	}
}

.footer__links-column-inner{
	@include breakpoint($screen-sm){
		@include valign-abs;
		right: $global-padding-md;
	}
	
}

#itunes-logo {
	.footer__logo{
		width: rem(100);

	}
}


/*
Logos
*/

.footer__logos-column{
	@include breakpoint($screen-sm){
		text-align: left;
	}
}

.footer__logo{
	width: $footer-logo-size;
	height: $footer-logo-size;
	margin: $footer-logo-padding;
	fill: $anchor-font-color;

}

.footer__logo--ttf{
	width: $footer-logo-size * .5;
}

/*
Copyright
*/

.footer__copyright-column{
	
}


/*
Links
*/

.footer__links-column{
	@include breakpoint($screen-sm){
		text-align: right;
		@include isolate(9);
	}
}

.footer__list-item{
	padding: 0 $global-padding-sm;
	font-weight: bold;
}