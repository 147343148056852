$loading-screen-loader-size: 116px;
$loading-screen-circle-size: 100px;
$loading-screen-icon-size: 66px;

.loading-screen{
    font-size: 40px;
    width: 100vw;
    height: 100vh;
    position: fixed;
    background-color: $black;
    z-index: 105;

    /*@include bg-pattern;*/
    /*@include ng-animate-fade;*/
}

.loading-screen__inner{
	@include screen-center;
	@include span(10);

	@include breakpoint($screen-sm){
		@include span(6);
	}

	@include breakpoint($screen-md){
		@include span(4);    
	}
}

.loading-screen__progress-wrapper {
    width: $loading-screen-loader-size;
    height: $loading-screen-loader-size;
    margin: auto;
    position: relative; 
}

.loading-screen__p{
	padding: 0 10%;
	font-style: italic;
}

.loading-screen__icon{
	height: $loading-screen-icon-size;
    @include calc(top, "((#{$loading-screen-loader-size}/2) - (#{$loading-screen-icon-size}/2))");
    @include halign-abs;
}

	.loading-screen__icon--home-page{
		width: 50px;
	}
	.loading-screen__icon--poi-the-heartbeat-of-a-nation{
		width: 60px;
	}
	.loading-screen__icon--poi-360-experience{
		margin-left: 5px;
	}

.loading-screen__circle{
	@include circle($loading-screen-circle-size, 1px solid $gray-light, none);
	@include halign-abs;
	@include calc(top, "((#{$loading-screen-loader-size}/2) - (#{$loading-screen-circle-size}/2)) - 1");

	@include animation-name(loading-screen__circle-keyframes);
	@include animation-duration(3s);
	@include animation-timing-function($ease-in-out-cubic);
	@include animation-iteration-count(infinite);
}

@keyframes loading-screen__circle-keyframes {
    0% {border-color: $gray-light;}
    50% {border-color: darken($white, 10%);}
    100% {border-color: $gray-light;}
}

.loading-screen__h1{
	margin: $global-padding-sm 0 $global-padding-xs;
}