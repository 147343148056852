.interview-profile{
	
}

.interview-profile__hero{
	position: relative;
	background-size: cover;
	background-position: center;
	height: $global-padding-xl * 4;

	@include breakpoint($screen-sm){
		height: $global-padding-xl * 5.5;
	}
}	

.interview-profile__hero-overlay{
	@include overlay-bg(0.3);
}

.interview-profile__hero-title{
	@include screen-center;
	width: 100%;

	h1{
		font-size: rem(32);	
	}

	@include breakpoint($screen-sm){
		top: 0;
		transform: translate(-50%, $global-padding-xl * 2);
	}
}

.interview-profile__article{
	@include article-page-inner;
	@include green-links;

	margin-top: $global-padding-md;

	@include breakpoint($screen-sm){
		margin-top: $global-padding-xl;
	}
}

.interview-profile__video{
	margin-top: -($global-padding-md * 3);
	margin-bottom: $global-padding-md;

	@include breakpoint($screen-sm){
		margin-top: -($global-padding-xl * 3);
		margin-bottom: $global-padding-lg;
	}
}

.interview-profile__loading{
	@include screen-center;
}

.interview-profile__previews{
	@include main-page-inner;
	margin-top: $global-padding-xl;
	padding-left: $global-padding-sm;
	padding-right: $global-padding-sm;
}