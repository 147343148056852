/*! normalize-scss | MIT/GPLv2 License | bit.ly/normalize-scss */

@import 'variables';
@import 'support-for';
@import 'vertical-rhythm';

// If we've customized any font variables, we'll need extra properties.
@if $base-font-size != 16px
  or $base-line-height != 24px
  or $base-unit != 'em'
  or $h1-font-size != 2    * $base-font-size
  or $h2-font-size != 1.5  * $base-font-size
  or $h3-font-size != 1.17 * $base-font-size
  or $h4-font-size != 1    * $base-font-size
  or $h5-font-size != 0.83 * $base-font-size
  or $h6-font-size != 0.67 * $base-font-size
  or $indent-amount != 40px {
  $normalize-vertical-rhythm: true !global;
}

/**
 * 1. Set default font family to sans-serif.
 * 2. Prevent iOS and IE text size adjust after device orientation change,
 *    without disabling user zoom.
 */

html {
  @if $normalize-vertical-rhythm or support-for(ie, 7) {
    // Correct text resizing oddly in IE 6/7 when body `font-size` is set using
    // `em` units.
    font-size: ($base-font-size / 16px) * 100%;
  }
  @if $normalize-vertical-rhythm {
    line-height: ($base-line-height / $base-font-size) * 1em;
  }
  font-family: $base-font-family; /* 1 */
  -ms-text-size-adjust: 100%; /* 2 */
  -webkit-text-size-adjust: 100%; /* 2 */
}

/**
 * Remove default margin.
 */

body {
  margin: 0;
}

/* HTML5 display definitions
   ========================================================================== */

/**
 * Correct `block` display not defined for any HTML5 element in IE 8/9.
 * Correct `block` display not defined for `details` or `summary` in IE 10/11
 * and Firefox.
 * Correct `block` display not defined for `main` in IE 11.
 */

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block;
}

/**
 * 1. Correct `inline-block` display not defined in IE 8/9.
 * 2. Normalize vertical alignment of `progress` in Chrome, Firefox, and Opera.
 */

audio,
canvas,
progress,
video {
  @if support-for(ie, 9) {
    display: inline-block; /* 1 */
    @if support-for(ie, 7) {
      *display: inline;
      *zoom: 1;
    }
  }
  vertical-align: baseline; /* 2 */
}

/**
 * Prevent modern browsers from displaying `audio` without controls.
 * Remove excess height in iOS 5 devices.
 */

audio:not([controls]) {
  display: none;
  height: 0;
}

@if support-for(ie, 10) {
  /**
   * Address `[hidden]` styling not present in IE 8/9/10.
   */

  [hidden] {
    display: none;
  }
}

/**
 * Hide the `template` element in IE 8/9/10/11, Safari, and Firefox < 22.
 */

template {
  display: none;
}

/* Links
   ========================================================================== */

@if support-for(ie, 10) {
  /**
   * Remove the gray background color from active links in IE 10.
   */

  a {
    background-color: transparent;
  }
}

/**
 * Improve readability of focused elements when they are also in an
 * active/hover state.
 */

a:active,
a:hover {
  outline: 0;
}

/* Text-level semantics
   ========================================================================== */

/**
 * Address styling not present in IE 8/9/10/11, Safari, and Chrome.
 */

abbr[title] {
  border-bottom: 1px dotted;
}

/**
 * Address style set to `bolder` in Firefox 4+, Safari, and Chrome.
 */

b,
strong {
  font-weight: bold;
}

/**
 * Address styling not present in Safari and Chrome.
 */

dfn {
  font-style: italic;
}

/**
 * Address variable `h1` font-size and margin within `section` and `article`
 * contexts in Firefox 4+, Safari, and Chrome.
 */

h1 {
  @include normalize-font-size($h1-font-size);
  @if $normalize-vertical-rhythm {
    @include normalize-line-height($h1-font-size);
  }

  /* Set 1 unit of vertical rhythm on the top and bottom margins. */
  @include normalize-margin(1 0, $h1-font-size);
}

@if $normalize-vertical-rhythm or support-for(ie, 7) {
  h2 {
    @include normalize-font-size($h2-font-size);
    @if $normalize-vertical-rhythm {
      @include normalize-line-height($h2-font-size);
    }
    @include normalize-margin(1 0, $h2-font-size);
  }

  h3 {
    @include normalize-font-size($h3-font-size);
    @if $normalize-vertical-rhythm {
      @include normalize-line-height($h3-font-size);
    }
    @include normalize-margin(1 0, $h3-font-size);
  }

  h4 {
    @include normalize-font-size($h4-font-size);
    @if $normalize-vertical-rhythm {
      @include normalize-line-height($h4-font-size);
    }
    @include normalize-margin(1 0, $h4-font-size);
  }

  h5 {
    @include normalize-font-size($h5-font-size);
    @if $normalize-vertical-rhythm {
      @include normalize-line-height($h5-font-size);
    }
    @include normalize-margin(1 0, $h5-font-size);
  }

  h6 {
    @include normalize-font-size($h6-font-size);
    @if $normalize-vertical-rhythm {
      @include normalize-line-height($h6-font-size);
    }
    @include normalize-margin(1 0, $h6-font-size);
  }
}

@if support-for(ie, 9) {
  /**
   * Address styling not present in IE 8/9.
   */

  mark {
    background: #ff0;
    color: #000;
  }
}

/**
 * Address inconsistent and variable font size in all browsers.
 */

small {
  font-size: 80%;
}

/**
 * Prevent `sub` and `sup` affecting `line-height` in all browsers.
 */

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

/* Embedded content
   ========================================================================== */

@if support-for(ie, 10) {
  /**
   * Remove border when inside `a` element in IE 8/9/10.
   */

  img {
    border: 0;
    @if support-for(ie, 7) {
      /* Improve image quality when scaled in IE 7. */
      -ms-interpolation-mode: bicubic;
    }
  }
}

/**
 * Correct overflow not hidden in IE 9/10/11.
 */

svg:not(:root) {
  overflow: hidden;
}

/* Grouping content
   ========================================================================== */

@if $normalize-vertical-rhythm or support-for(ie, 7) {
  /**
   * Address margins set differently in IE 6/7.
   */

  dl,
  menu,
  ol,
  ul {
    @include normalize-margin(1 0);
  }
}

@if $normalize-vertical-rhythm {
  /**
   * Turn off margins on nested lists.
   */

  ol,
  ul {
    ol,
    ul {
      margin: 0;
    }
  }
}

@if $normalize-vertical-rhythm or support-for(ie, 7) {
  dd {
    margin: 0 0 0 $indent-amount;
  }

  /**
   * Address paddings set differently in IE 6/7.
   */

  menu,
  ol,
  ul {
    padding: 0 0 0 $indent-amount;
  }
}

@if support-for(ie, 7) {
  /**
   * Correct list images handled incorrectly in IE 7.
   */

  nav ul,
  nav ol {
    list-style: none;
    list-style-image: none;
  }
}

@if $normalize-vertical-rhythm or support-for(ie, 7) {
  /**
   * Set 1 unit of vertical rhythm on the top and bottom margin.
   */

  blockquote {
    @include normalize-margin(1 $indent-amount);
  }
}

@if $normalize-vertical-rhythm or support-for(ie, 9) or support-for(safari, 6) {
  /**
   * Address margin not present in IE 8/9 and Safari.
   */

  figure {
    @include normalize-margin(1 $indent-amount);
  }
}

/**
 * Address differences between Firefox and other browsers.
 */

hr {
  @if support-for(firefox, 28) {
    -moz-box-sizing: content-box;
  }
  box-sizing: content-box;
  height: 0;
}

@if $normalize-vertical-rhythm or support-for(ie, 7) {
  /**
   * Set 1 unit of vertical rhythm on the top and bottom margin.
   */

  p,
  pre {
    @include normalize-margin(1 0);
  }
}

/**
 * Contain overflow in all browsers.
 */

pre {
  overflow: auto;
}

/**
 * Address odd `em`-unit font size rendering in all browsers.
 */

code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  @if support-for(ie, 6) {
    _font-family: 'courier new', monospace;
  }
  font-size: 1em;
}

/* Forms
   ========================================================================== */

/**
 * Known limitation: by default, Chrome and Safari on OS X allow very limited
 * styling of `select`, unless a `border` property is set.
 */

@if support-for(ie, 7) {
  /**
   * Correct margin displayed oddly in IE 6/7.
   */

  form {
    margin: 0;
  }
}

/**
 * 1. Correct color not being inherited.
 *    Known issue: affects color of disabled elements.
 * 2. Correct font properties not being inherited.
 * 3. Address margins set differently in Firefox 4+, Safari, and Chrome.
 * 4. Address `font-family` inconsistency between `textarea` and other form in IE 7
 * 5. Improve appearance and consistency with IE 6/7.
 */

button,
input,
optgroup,
select,
textarea {
  color: inherit; /* 1 */
  font: inherit; /* 2 */
  margin: 0; /* 3 */
  @if support-for(ie, 7) {
    *font-family: $base-font-family; /* 4 */
    *vertical-align: middle; /* 5 */
  }
}

/**
 * Address `overflow` set to `hidden` in IE 8/9/10/11.
 */

button {
  overflow: visible;
}

/**
 * Address inconsistent `text-transform` inheritance for `button` and `select`.
 * All other form control elements do not inherit `text-transform` values.
 * Correct `button` style inheritance in Firefox, IE 8/9/10/11, and Opera.
 * Correct `select` style inheritance in Firefox.
 */

button,
select {
  text-transform: none;
}

/**
 * 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
 *    and `video` controls.
 * 2. Correct inability to style clickable `input` types in iOS.
 * 3. Improve usability and consistency of cursor style between image-type
 *    `input` and others.
 * 4. Remove inner spacing in IE 7 without affecting normal text inputs.
 *    Known issue: inner spacing remains in IE 6.
 */

button,
html input[type="button"], /* 1 */
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button; /* 2 */
  cursor: pointer; /* 3 */
  @if support-for(ie, 7) {
    *overflow: visible; /* 4 */
  }
}

/**
 * Re-set default cursor for disabled elements.
 */

button[disabled],
html input[disabled] {
  cursor: default;
}

/**
 * Remove inner padding and border in Firefox 4+.
 */

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

/**
 * Address Firefox 4+ setting `line-height` on `input` using `!important` in
 * the UA stylesheet.
 */

input {
  line-height: normal;
}

@if support-for(ie, 10) {
  /**
   * It's recommended that you don't attempt to style these elements.
   * Firefox's implementation doesn't respect box-sizing, padding, or width.
   *
   * 1. Address box sizing set to `content-box` in IE 8/9/10.
   * 2. Remove excess padding in IE 8/9/10.
   * 3. Remove excess padding in IE 7.
   *    Known issue: excess padding remains in IE 6.
   */

  input[type="checkbox"],
  input[type="radio"] {
    box-sizing: border-box; /* 1 */
    padding: 0; /* 2 */
    @if support-for(ie, 7) {
      *height: 13px; /* 3 */
      *width: 13px; /* 3 */
    }
  }
}

/**
 * Fix the cursor style for Chrome's increment/decrement buttons. For certain
 * `font-size` values of the `input`, it causes the cursor style of the
 * decrement button to change from `default` to `text`.
 */

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

/**
 * 1. Address `appearance` set to `searchfield` in Safari and Chrome.
 * 2. Address `box-sizing` set to `border-box` in Safari and Chrome.
 */

input[type="search"] {
  -webkit-appearance: textfield; /* 1 */
  @if support-for(safari, 5) or support-for(chrome, 9) {
    -webkit-box-sizing: content-box;
  }
  box-sizing: content-box; /* 2 */

  /**
   * Remove inner padding and search cancel button in Safari and Chrome on OS X.
   * Safari (but not Chrome) clips the cancel button when the search input has
   * padding (and `textfield` appearance).
   */

  &::-webkit-search-cancel-button,
  &::-webkit-search-decoration {
    -webkit-appearance: none;
  }
}

/**
 * Define consistent border, margin, and padding.
 */

fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}

/**
 * 1. Correct `color` not being inherited in IE 8/9/10/11.
 * 2. Remove padding so people aren't caught out if they zero out fieldsets.
 * 3. Correct alignment displayed oddly in IE 6/7.
 */

legend {
  @if support-for(ie, 11) {
    border: 0; /* 1 */
  }
  padding: 0; /* 2 */
  @if support-for(ie, 7) {
    *margin-left: -7px; /* 3 */
  }
}

/**
 * Remove default vertical scrollbar in IE 8/9/10/11.
 */

textarea {
  overflow: auto;
}

/**
 * Don't inherit the `font-weight` (applied by a rule above).
 * NOTE: the default cannot safely be changed in Chrome and Safari on OS X.
 */

optgroup {
  font-weight: bold;
}

/* Tables
   ========================================================================== */

/**
 * Remove most spacing between table cells.
 */

table {
  border-collapse: collapse;
  border-spacing: 0;
}

td,
th {
  padding: 0;
}
